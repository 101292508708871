import React, { useContext } from "react";
import styled from "styled-components";
import { WorkOutlineOutlined, PinDropOutlined } from "@material-ui/icons";
import { StringsContext } from "../DataFactory/useStrings";

const TaskDiv = styled.div`
  position: relative;
  svg {
    margin-top: 5px;
  }
  & h4 {
    font-size: 16px;
    margin: 2px 0;
    font-weight: 400;
  }
  & p {
    font-size: 15px;
    margin: 0;
    font-weight: 400;
    color: #777;
    line-height: 22px;
  }
`;
const PickupDiv = styled.div`
  display: inline-flex;
  width: 95%;
  &:after {
    content: " ";
    position: absolute;
    display: block;
    top: 18px;
    left: 12px;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: rgba(0, 0, 0, 0.25);
    min-height: 15px;
    max-height: 20px;
  }
  div {
    display: inline-block;
    padding-left: 10px;
    width: 90%;
    margin-bottom: 10px;
    vertical-align: top;
    padding-bottom: 10px;
    box-sizing: border-box;
  }
`;
const DeliveryDiv = styled.div`
  display: inline-flex;
  width: 95%;
  div {
    display: inline-block;
    padding-left: 10px;
    width: 90%;
    vertical-align: top;
    padding-bottom: 10px;
  }
`;

export default function GTaskView(props) {
  const [strings] = useContext(StringsContext);
  return (
    <>
      {props.orderClass == "courier" || props.orderClass == "atm" ? (
        <TaskDiv>
          <DeliveryDiv
            style={{
              display: "inline-flex",
              width: props.orderClass == "atm" ? "100%" : "calc(100% - 175px)",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                margin: "5px 0 5px",
                padding: 0,
              }}
            >
              <h5
                style={{
                  fontWeight: "440",
                  fontStyle: "italic",
                  color: "#777",
                  margin: " 0",
                  width: "100px",
                }}
              >
                {strings.w_address} :
              </h5>
              <div>
                <h5 style={{ margin: 0, fontSize: "15px" }}>
                  {props.task?.delivery_payload?.title_display}
                </h5>
                <p style={{ color: "#333", flex: 1 }}>
                  {props.task?.delivery_address}
                </p>
              </div>
            </div>

            {props.task?.delivery_payload?.notes && (
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  margin: "10px 0 5px",
                  padding: 0,
                }}
              >
                <h5
                  style={{
                    margin: "0",
                    width: "60px",
                    fontStyle: "italic",
                    color: "#777",
                  }}
                >
                  {strings.w_notes} : &nbsp;
                </h5>
                <p
                  style={{
                    color: "#333",
                    fontSize: "14px",
                    flex: 1,
                  }}
                >
                  {props.task?.delivery_payload?.notes}
                </p>
              </div>
            )}
          </DeliveryDiv>

          {props.orderClass !== "atm" && (
            <div
              style={{
                display: "inline-block",
                float: "right",
                marginTop: "-15px",
              }}
            >
              <h5 style={{ margin: "5px 0" }}>{strings.w_recipient_info}</h5>
              <div style={{ background: "#f4f5f9", padding: "5px 10px" }}>
                <p>
                  <small>
                    {strings.w_name} :{" "}
                    {props.task?.delivery_payload?.rec_name || "-"}
                  </small>
                </p>
                <p>
                  <small>
                    {strings.w_phone} :{" "}
                    {props.task?.delivery_payload?.rec_phone || "-"}
                  </small>
                </p>
              </div>
            </div>
          )}
        </TaskDiv>
      ) : (
        <TaskDiv>
          <PickupDiv>
            <PinDropOutlined />
            <div>
              <h4>
                {props.type === "pickup"
                  ? props.task?.delivery_payload?.title_display || ""
                  : props.storeTitle}
              </h4>
              <p>{props.task.pickup_address}</p>
            </div>
          </PickupDiv>
          <DeliveryDiv>
            <WorkOutlineOutlined />
            <div>
              <h4>
                {props.type === "pickup"
                  ? props.storeTitle
                  : props.task?.delivery_payload?.title_display || ""}
              </h4>
              <p>{props.task.delivery_address}</p>
            </div>
          </DeliveryDiv>
        </TaskDiv>
      )}
    </>
  );
}
