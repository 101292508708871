import React, { useState, useContext } from "react";
import GMap from "../gComponents/gMaps";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function AddressForm(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [addressLocation, setAddressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.data.latitude),
          lng: parseFloat(props.data.longitude),
        }
      : {
          lat: process.env.REACT_APP_DEFAULT_LATITUDE,
          lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
        }
  );

  const addressTitle = [
    {
      key: strings.w_house,
      value: "house",
    },
    {
      key: strings.w_apartment,
      value: "apartment",
    },
    {
      key: strings.w_office,
      value: "office",
    },
    {
      key: strings.w_hotel,
      value: "hotel",
    },
    {
      key: strings.w_other,
      value: "other",
    },
  ];

  const initialValues = {
    type: "saved",
    customer_id: props.id,
    title: !props.isEmpty
      ? props.data.title
        ? addressTitle.filter((at) => at.value == props.data.title).length > 0
          ? props.data.title
          : "other"
        : "house"
      : "house",
    other_title: !props.isEmpty
      ? props.data.title
        ? addressTitle.filter((at) => at.value == props.data.title).length > 0
          ? ""
          : props.data.title
        : props.data.title
      : "",
    line1: !props.isEmpty ? props.data.line1 : "",
    line2: !props.isEmpty ? props.data.line2 : "",
    phone: !props.isEmpty ? props.data.phone : "",
    latitude: !props.isEmpty ? props.data.latitude : "",
    longitude: !props.isEmpty ? props.data.longitude : "",
    city: !props.isEmpty ? props.data.city : "",
    state: !props.isEmpty ? props.data.state : "",
    country: !props.isEmpty ? props.data.country : "",
    zipcode: !props.isEmpty ? props.data.zipcode : "",
    apartment: !props.isEmpty ? props.data.apartment : "",
    building_name: !props.isEmpty ? props.data.building_name : "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendValues = { ...values };
    sendValues.title =
      sendValues.title === "other" ? sendValues.other_title : sendValues.title;
    console.log(sendValues);

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "addresses/" + props.data.id : "addresses";
    ApiService({ method: hitMethod, route: hitUrl, body: sendValues }).then(
      (response) => {
        console.log(response.data);
        openSnackbar(response.data.message);
        if (response.data.status_code === 1) {
          props.onSubmit();
          props.setAfvisible(false);
        }
        setIsBtnDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    line1: Yup.string().required(strings.w_required),
    title: Yup.string().required(strings.w_required),
    apartment: Yup.string().required(strings.w_required),
    phone: Yup.string().required(strings.w_required),
  });

  const handleClose = (e) => {
    if (e.target.id != 2721) {
      return;
    }
    props.setAfvisible(false);
  };

  if (props.afvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {props.isEmpty === true
          ? strings.w_create_address
          : strings.w_edit_address}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GMap
                centerLocation={addressLocation}
                search={true}
                height="30vh"
                type="createAddress"
                onChange={(address, loc) => {
                  values.latitude = loc.lat();
                  values.longitude = loc.lng();
                  values.line1 = address.address;
                  values.city = address.city;
                  values.state = address.state;
                  values.country = address.country;
                  values.zipcode = address.zipcode;
                  setFieldValue("line1", address.address);
                }}
                padding={true}
              />

              <FormikControl
                control="input"
                type="text"
                placeholder={strings.s_address_line1}
                name="line1"
                className="noBorder"
                readOnly={true}
              />

              <FormikControl
                control="select"
                as="select"
                name="title"
                label={strings.w_building_type}
                options={addressTitle}
                key_title="key"
                key_value="value"
              />

              {values.title === "other" && (
                <FormikControl
                  control="input"
                  type="text"
                  label={strings?.s_address_line_title}
                  name="other_title"
                />
              )}

              <FormikControl
                control="input"
                type="text"
                label={
                  strings.w_address_apartment + " (" + strings.w_required + ")"
                }
                name="apartment"
                style={{ height: "40px" }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_building_name}
                name="building_name"
                style={{ height: "40px" }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.s_address_line2}
                name="line2"
              />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_phone}
                name="phone"
                style={{ height: "40px" }}
                maxLength={13}
                onChange={(event) => {
                  const re = /^[0-9+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />
              <br />
              <GButton
                disabled={isBtnDisabled}
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                type="submit"
                style={{ width: "calc(100% - 30px)", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
