import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import { ApiService } from "../services";
import { Add } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import GTabs from "../gComponents/gTabs";
import GListCard from "../gComponents/gListCards";
import { useSnackbar } from "react-simple-snackbar";
import DocumentTypeForm from "../forms/documentTypesForm";
import GText from "../gComponents/gText";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

export default function DocumentTypes() {
  const [strings] = useContext(StringsContext);
  let { type } = useParams();

  const docRoutes = [
    {
      title: strings.w_customer,
      identifier: "customer",
      link: "/settings/document-types/customer",
      state: "setting",
    },
    {
      title: strings.w_manager,
      identifier: "manager",
      link: "/settings/document-types/manager",
      state: "setting",
    },
    {
      title: strings.w_driver,
      identifier: "driver",
      link: "/settings/document-types/driver",
      state: "setting",
    },
    {
      title: strings.w_store,
      identifier: "store",
      link: "/settings/document-types/store",
      state: "setting",
    },
  ];

  const [typeValue, setTypeValue] = React.useState(type || "customer");

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={strings.w_document_types}
        style={{ marginBottom: "20px" }}
      />

      <GTabs routes={docRoutes} active={typeValue}></GTabs>

      <Switch>
        <div className="listPaper">
          <Route path="/settings/document-types/:type">
            <Child onChange={(newValue) => setTypeValue(newValue)} />
          </Route>
        </div>
      </Switch>
    </div>
  );
}

function Child(props) {
  let { type } = useParams();
  const [strings] = useContext(StringsContext);
  let typeId =
    type === "customer"
      ? "1"
      : type === "manager"
      ? "2"
      : type === "driver"
      ? "3"
      : "4";
  const [selectedDocType, setSelectedDocType] = useState({});
  const [dtvisible, setDtvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [openSnackbar] = useSnackbar();
  const [initialLoading, setInitialLoading] = useState(true);
  const [localizableKeys, setLocalizableKeys] = useState([]);
  const {
    loading,
    Placeholder,
    data: docTypeData,
    CardShimmer,
    refreshData,
    extra,
  } = useDataFctory("doc_types", false, { user_type: typeId });

  useEffect(() => {
    if (initialLoading == false) {
      refreshData({ user_type: typeId });
      props.onChange(type);
    }

    setInitialLoading(false);
  }, [type]);

  useEffect(() => {
    if (extra?.localizable_keys && extra?.localizable_keys.length > 0) {
      let localKeys = [];
      extra.localizable_keys.map((key) => {
        localKeys.push({
          title: (key.charAt(0).toUpperCase() + key.slice(1)).replace("_", " "),
          value: key,
        });
      });
      setLocalizableKeys(localKeys || []);
    }
  }, [extra]);

  function deleteDocType(selectedId) {
    ApiService({ method: "DELETE", route: "document/types/" + selectedId })
      .then((response) => {
        console.log("gh" + JSON.stringify(response));
        if (response.data.status_code === 1) {
          let selectedIndex = docTypeData.findIndex((p) => p.id == selectedId);
          if (selectedIndex > -1) {
            docTypeData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <GButton
        variant="linkable"
        onClick={() => {
          setIsEmpty(true);
          setSelectedDocType({});
          setDtvisible(true);
        }}
        style={{
          margin: "-60px -15px 0px",
          float: "right",
        }}
      >
        <Add style={{ fontSize: "1.8rem" }} />
      </GButton>

      {loading === true ? (
        <CardShimmer />
      ) : docTypeData.length === 0 ? (
        <Placeholder />
      ) : (
        docTypeData.map((d) => {
          return (
            <GListCard
              setting={strings.w_document_type}
              id={d.id}
              title={d.title}
              listActions={true}
              key={d.id}
              onEdit={(selectedRecord) => {
                setSelectedDocType(selectedRecord);
                setIsEmpty(false);
                setDtvisible(true);
              }}
              onDelete={deleteDocType}
              data={d}
            ></GListCard>
          );
        })
      )}

      <Modal
        isOpen={dtvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setDtvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Document Type Form Modal"
      >
        <DocumentTypeForm
          dtvisible={dtvisible}
          setDtvisible={setDtvisible}
          isEmpty={isEmpty}
          type={typeId}
          data={selectedDocType}
          localizableKeys={localizableKeys || []}
          onSubmit={(data) => {
            let selectedIndex = docTypeData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              docTypeData[selectedIndex] = { ...data };
            } else {
              docTypeData.push(data);
            }
          }}
          onLocalizationRemove={(ind) => {
            let findInd = docTypeData.findIndex(
              (nId) => nId?.id == selectedDocType.id
            );

            if (findInd > -1) {
              docTypeData[findInd].localizations.splice(ind, 1);
            }
          }}
          onLocalizationUpdate={(ind, data) => {
            let findInd = docTypeData.findIndex(
              (nId) => nId?.id == selectedDocType.id
            );

            if (findInd > -1) {
              docTypeData[findInd].localizations[ind] = data;
            }
          }}
        />
      </Modal>
    </div>
  );
}
