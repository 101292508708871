import React, { useContext, useState } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { StringsContext } from "../DataFactory/useStrings";
import GImageEditor from "../gComponents/gImageEditor";
import Modal from "react-modal";
import LocalizationForm from "../forms/localizationForm";
import { SettingsContext } from "../DataFactory/useSettings";

export default function CreatDocType(props) {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const [openSnackbar] = useSnackbar();
  const [localizationsArray, setLocalizationsArray] = useState(
    !props.isEmpty ? props.data?.localizations : []
  );
  const [localizationVisible, setLocalizationVisible] = useState(false);

  const initialValues = {
    user_type: props.type ? props.type : "",
    title: !props.isEmpty ? props.data.title : "",
    type: !props.isEmpty ? props.data.type : "",
    status: !props.isEmpty ? props.data.status : 1,
    instruction_desc: !props.isEmpty ? props.data.instruction_desc : "",
    instruction_image: !props.isEmpty ? props.data.instruction_image : "",
    instruction_title: !props.isEmpty ? props.data.instruction_title : "",
  };

  const onSubmit = (values) => {
    console.log(values);
    values.localizations = localizationsArray;

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "document/types/" + props.data.id
      : "document/types";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setDtvisible(false);
          props.onSubmit(response.data.data);
        }
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
  });

  if (props.dtvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? strings.w_edit_document_type
          : strings.w_create_document_type}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
              />
              <FormikControl
                control="radio"
                label={strings.w_type}
                name="type"
                options={[
                  { key: strings.w_image, value: "image" },
                  { key: strings.w_text, value: "text" },
                ]}
                key_title="key"
                key_value="value"
                divstyle={{ display: "block", padding: "5px 0" }}
              />
              <FormikControl
                control="toggle"
                label={strings.w_status}
                isToggled={values.status}
                name="status"
                onToggle={() => {
                  setFieldValue("status", values.status == 1 ? 0 : 1);
                }}
              />
              <br />

              <div style={{ margin: "0 15px" }}>
                <label>{strings.w_instruction_image}</label>
                <GImageEditor
                  onSubmit={(newValue, id, thumb_val) => {
                    values.instruction_image = newValue;
                  }}
                  id="instruction_image"
                  image={
                    values.instruction_image
                      ? ImgUrl("document_instructions") +
                        "/" +
                        values.instruction_image
                      : ""
                  }
                  type="document_instructions"
                  style={{ height: "150px", paddingTop: "60px" }}
                  divstyle={{ margin: "0", display: "block", paddingLeft: 0 }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_instruction_title}
                name="instruction_title"
              />
              <FormikControl
                control="input"
                as="textarea"
                label={strings.w_instruction_desc}
                name="instruction_desc"
                rows="4"
              />

              {settings?.locales && settings?.locales.length > 0 && (
                <div style={{ margin: "15px 15px" }}>
                  <label>{strings.w_localizations}</label>

                  {localizationsArray.length > 0 ? (
                    <GButton
                      variant="linkable"
                      onClick={() => setLocalizationVisible(true)}
                    >
                      {localizationsArray.length}{" "}
                      {strings.w_localizations_added}
                    </GButton>
                  ) : (
                    <GButton
                      variant="linkable"
                      children={strings.w_add_localization}
                      onClick={() => setLocalizationVisible(true)}
                    />
                  )}
                </div>
              )}
              <br />

              <GButton
                type="submit"
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>

      <Modal
        isOpen={localizationVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setLocalizationVisible(false)}
        style={{
          content: {
            width: "600px",
          },
        }}
        contentLabel="Localization Form Modal"
      >
        <LocalizationForm
          localizationVisible={localizationVisible}
          setLocalizationVisible={setLocalizationVisible}
          data={localizationsArray}
          onSubmit={(data) => {
            setLocalizationsArray([...data]);
          }}
          keyArray={
            props.localizableKeys || [
              { title: strings.w_title, value: "title" },
              {
                title: strings.w_instruction_title,
                value: "instruction_title",
              },
              { title: strings.w_instruction_desc, value: "instruction_desc" },
            ]
          }
          variables={[]}
          onLocalizationRemove={(ind) => {
            props.onLocalizationRemove(ind);
          }}
          onLocalizationUpdate={(ind, data) => {
            props.onLocalizationUpdate(ind, data);
          }}
        />
      </Modal>
    </>
  );
}
