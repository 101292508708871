import React, { useState, useEffect, useContext } from "react";
import { makeStyles, TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService, getUserType, isPermissionExist } from "../services";
import { Add, Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import AddressForm from "../forms/addressForm";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

const useStyles = makeStyles({
  container: {
    maxHeight: "calc(100vh - 320px)",
    overflow: "overlay",
    minHeight: "360px",
  },
});

export default function Addresses(props) {
  const [strings] = useContext(StringsContext);
  const [initialLoading, setInitialLoading] = useState(true);
  const { title, value } = props;
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [selectedAddressData, setSelectedAddressData] = useState({});
  const [currentPage, setCurrentPage] = useState("");
  const [afvisible, setAfvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  let obj = {};
  obj[title] = value;
  obj["page"] = currentPage;
  const {
    loading,
    data: addressData,
    pagination: addressPaginationData,
    Placeholder,
    refreshData,
    TableShimmer,
  } = useDataFctory("addresses", true, obj);

  const addressTitle = [
    {
      key: strings.w_house,
      value: "house",
    },
    {
      key: strings.w_apartment,
      value: "apartment",
    },
    {
      key: strings.w_office,
      value: "office",
    },
    {
      key: strings.w_hotel,
      value: "hotel",
    },
    {
      key: strings.w_other,
      value: "other",
    },
  ];

  const columns = [
    { id: "address_line1", label: strings.s_address_line1, minWidth: 150 },
    { id: "state", label: strings.w_building_type, minWidth: 60 },
    { id: "city", label: strings.w_address_apartment, minWidth: 50 },
    { id: "country", label: strings.w_building_name, minWidth: 60 },
    getUserType() || isPermissionExist("customer_update")
      ? {
          id: "actions",
          label: strings.w_actions,
          minWidth: 60,
          align: "center",
        }
      : "",
  ];

  useEffect(() => {
    console.log(props);
    if (props.type) {
      if (props.onChange) {
        props.onChange("address");
      }
    }
    if (initialLoading == false && props.value) {
      obj[title] = value;
      obj["page"] = currentPage;
      refreshData(obj);
    }
    setInitialLoading(false);
  }, [props.value, currentPage]);

  const deleteAddress = () => {
    ApiService({ method: "DELETE", route: "addresses/" + selectedAddressId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          refreshData(obj);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setCurrentPage(NewPageNo);
  };

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {props.type ? "" : <h1>{strings.w_addresses}</h1>}

      {getUserType() || isPermissionExist("customer_update") ? (
        <GButton
          variant="linkable"
          onClick={() => {
            setSelectedAddressData({});
            setIsEmpty(true);
            setAfvisible(true);
          }}
          style={{
            margin: "-40px 0px 10px",
            float: "right",
          }}
        >
          <Add fontSize="large" />
        </GButton>
      ) : (
        ""
      )}

      <div className="listPaper">
        {addressData.length > 0 ? (
          <GPagination
            count={addressPaginationData.total}
            from={addressPaginationData.from}
            to={addressPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(addressPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(addressPaginationData.prev_page_url)
            }
            rowsPerPage={addressPaginationData.per_page}
            prevDisabled={addressPaginationData.prev_page_url ? false : true}
            nextDisabled={addressPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
          ""
        )}
        {loading === true ? (
          <TableShimmer />
        ) : (
          <TableContainer className={classes.container}>
            {addressData.length === 0 ? (
              <Placeholder />
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addressData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>{c.line1}</TableRowData>
                        <TableRowData>
                          {c.title
                            ? addressTitle.filter((at) => at.value == c.title)
                                .length > 0
                              ? addressTitle
                                  .filter((at) => at.value == c.title)
                                  .map((t) => {
                                    return t.key;
                                  })
                              : c.title
                            : "-"}
                        </TableRowData>
                        <TableRowData>{c.apartment || "-"}</TableRowData>
                        <TableRowData>{c.building_name || "-"}</TableRowData>
                        {getUserType() ||
                        isPermissionExist("customer_update") ? (
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <GButton
                              variant="linkable"
                              onClick={(e) => {
                                setSelectedAddressData(c);
                                setIsEmpty(false);
                                setAfvisible(true);
                              }}
                            >
                              <Edit fontSize="small" />
                            </GButton>

                            <GButton
                              variant="linkable"
                              onClick={(e) => {
                                setSelectedAddressId(c.id);
                                setDialogOpen(true);
                              }}
                            >
                              <Delete fontSize="small" />
                            </GButton>
                          </TableRowData>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_address_title}
          text={strings.s_delete_address_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteAddress();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={afvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAfvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Address Form Modal"
      >
        <AddressForm
          afvisible={afvisible}
          setAfvisible={setAfvisible}
          data={selectedAddressData}
          onSubmit={() => {
            refreshData(obj);
          }}
          type={props.type}
          id={props.value}
          isEmpty={isEmpty}
        />
      </Modal>
    </div>
  );
}
