import React, { useState, useEffect, useRef, useContext } from "react";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import {
  ImgUrl,
  csvFileApiService,
  ApiService,
  getUserType,
  isPermissionExist,
} from "../services";
import { ShoppingBasket, Edit, Delete } from "@material-ui/icons";
import itemPlaceholder from "../assets/images/placeholders/p-item.png";
import CatIcon from "../assets/images/category.svg";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import { useHistory, Link } from "react-router-dom";
import ProductCreatePopup from "../forms/productCreateForm";
import ProductEditPopup from "../forms/productEditForm";
import QueryWith from "../Query";
import GSelectbox from "../gComponents/gSelectbox";
import { GSearch } from "../gComponents/gSearch";
import { StoreFilter } from "../components/storeFilterComponent";
import GSwitch from "../gComponents/gSwitch";
import useSettings from "../useSettings";
import Modal from "react-modal";
import GActions from "../gComponents/gActionsStyle";
import { StringsContext } from "../DataFactory/useStrings";
import ProductUnavailabilityReasons from "./productTurnOffReasons";

export default function Products(props) {
  const [strings] = useContext(StringsContext);
  const [openSnackbar] = useSnackbar();
  const fileInput = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [productsData, setProductsData] = useState([]);
  const [productsPaginationData, setProductsPaginationData] = useState("");
  const [productsLocalizableKeys, setProductsLocalizableKeys] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [selectedProductId, setSelectedProductId] = useState("");
  const [pcvisible, setPcvisible] = useState(false);
  const [pevisible, setPevisible] = useState(false);
  const [productCatData, setProductCatData] = useState([]);
  const settings = useSettings(true);
  const [showTurnOffReasonsForm, setShowTurnOffReasonsForm] = useState(false);

  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    search: params.get("search") ? params.get("search") : "",
    category: params.get("category") ? params.get("category") : "",
    store_id: params.get("store_id") ? params.get("store_id") : "",
    status: params.get("status") ? params.get("status") : "",
  });

  const statusFilters = [
    {
      title: strings.w_active,
      value: "1",
    },
    {
      title: strings.w_inactive,
      value: "0",
    },
  ];

  const columns = [
    { id: "title", label: strings.w_title, minWidth: 130 },
    { id: "category", label: strings.w_category, minWidth: 150 },
    { id: "photo", label: strings.w_photo, minWidth: 50, type: "image" },
    { id: "price", label: strings.w_price, minWidth: 120, align: "center" },
    {
      id: "product_stock",
      label: strings.w_stock,
      minWidth: 90,
      align: "center",
    },
    { id: "status", label: strings.w_status, minWidth: 60 },
    getUserType() ||
    isPermissionExist("product_update") ||
    isPermissionExist("product_destroy")
      ? {
          id: "actions",
          label: strings.w_actions,
          minWidth: 60,
          align: "center",
        }
      : "",
  ];

  const getProducts = () => {
    if (!filters.store_id) {
      return;
    }
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/products",
        state: "product",
        search: params[0],
      });
    }
    ApiService({ method: "GET", route: "products", body: filters })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setProductsData(response.data.data.data);
          setProductsPaginationData(response.data.data);
          let localKeys = [];
          response.data.localizable_keys.map((key) => {
            localKeys.push({
              title: key.charAt(0).toUpperCase() + key.slice(1),
              value: key,
            });
          });
          setProductsLocalizableKeys(localKeys || []);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!filters.store_id) {
      setProductCatData([]);
      setProductsData([]);
      setProductsPaginationData("");
      return;
    }
    setIsLoading(true);
    ApiService({
      method: "GET",
      route: "product/group?store_id=" + filters.store_id,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setProductCatData(response.data.data);
        getProducts();
      }
    });
  }, [filters.store_id]);

  useEffect(() => {
    getProducts();
  }, [filters.category, filters.search, filters.status, filters.page]);

  function addDefaultLogo(e) {
    e.target.src = itemPlaceholder;
  }

  const updateSubaCategory = (newVal, selectedProductId, index) => {
    ApiService({
      method: "PUT",
      route: "products/" + selectedProductId,
      body: {
        categories: newVal,
      },
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        productsData[index] = response.data.data;
        setProductsData([...productsData]);
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  const deleteProduct = () => {
    ApiService({ method: "DELETE", route: "products/" + selectedProductId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getProducts();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  const handleToggleStatus = (newVal, id, ind) => {
    ApiService({
      method: "PUT",
      route: "products/" + id,
      body: {
        status: newVal,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          productsData[ind].status = newVal;
          setProductsData([...productsData]);
        } else {
          getProducts();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function uploadBukItems(file) {
    csvFileApiService("POST", "bulk/products", {
      csv: file,
      store_id: filters.store_id,
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          fileInput.current.value = "";
          getProducts();
        }
        openSnackbar(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="mainInnerView">
      <h1>
        <ShoppingBasket /> {strings.w_products}
        <StoreFilter
          value={filters.store_id}
          isStored={true}
          style={{
            marginLeft: "auto",
            float: "right",
          }}
          handleStoreChange={(value) => {
            console.log(value);
            if (value) {
              setFilters({ store_id: value });
            } else {
              setFilters({ store_id: value, category: "", search: "" });
            }
          }}
          handleClick={(v) =>
            setFilters({ store_id: v, category: "", search: "" })
          }
        />
      </h1>

      {filters.store_id ? (
        <React.Fragment>
          <div
            style={{
              display: "inline-flex",
              width: "60%",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
              verticalAlign: "top",
            }}
          >
            <GSearch
              placeholder={strings.s_search_by_title}
              value={filters.search}
              handleChange={(val) => {
                setFilters({
                  search: val,
                  store_id: filters.store_id,
                });
              }}
            />

            <GSelectbox
              placeholder={strings.w_select_category}
              defaultvalue={filters.category}
              data={productCatData}
              id="category_filter"
              option_key="title"
              option_value="id"
              onChange={(newVal) => {
                setFilters({ ...filters, category: newVal });
              }}
              style={{
                border: "1px solid #e2e2e2",
                height: "40px",
              }}
            />
            <GSelectbox
              placeholder={strings.w_all}
              defaultvalue={filters.status}
              data={statusFilters}
              id="item_status_filter"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                setFilters({ ...filters, status: newVal });
              }}
              style={{
                border: "1px solid #e2e2e2",
                height: "40px",
                minWidth: "150px",
              }}
            />
          </div>
          <div
            style={{
              display: "inline-block",
              width: "40%",
              paddingTop: "5px",
            }}
          >
            <input
              type="file"
              id="itemFile"
              ref={fileInput}
              onChange={(e) => {
                console.log(e.target.files[0]);
                uploadBukItems(e.target.files[0]);
              }}
              style={{ display: "none" }}
            />
            {getUserType() || isPermissionExist("product_store") ? (
              <GButton
                variant="outlined"
                children={strings.w_bulk_upload}
                onClick={() => {
                  document.getElementById("itemFile").click();
                }}
                style={{
                  float: "right",
                  marginTop: "-5px",
                  padding: "0.5rem 1rem ",
                  marginLeft: "10px",
                }}
              />
            ) : (
              ""
            )}
            {getUserType() || isPermissionExist("product_store") ? (
              <GButton
                variant="condensed"
                children={strings.w_add}
                onClick={() => {
                  setPcvisible(true);
                }}
                style={{
                  float: "right",
                  marginTop: "-5px",
                  padding: "0.6rem 2rem",
                }}
              />
            ) : (
              ""
            )}
            {productsData.length > 0 && (
              <GPagination
                count={productsPaginationData.total}
                from={productsPaginationData.from}
                to={productsPaginationData.to}
                onChangeNextPage={() =>
                  handleChangePage(productsPaginationData.next_page_url)
                }
                onChangePrevPage={() =>
                  handleChangePage(productsPaginationData.prev_page_url)
                }
                rowsPerPage={productsPaginationData.per_page}
                prevDisabled={
                  productsPaginationData.prev_page_url ? false : true
                }
                nextDisabled={
                  productsPaginationData.next_page_url ? false : true
                }
                listStyle={{ margin: "10px 0 0" }}
              ></GPagination>
            )}
          </div>

          <div className="listPaper" style={{ marginTop: "15px" }}>
            {isLoading === true ? (
              <GTableShimmer></GTableShimmer>
            ) : (
              <TableContainer>
                {productsData.length === 0 ? (
                  <GEmpty></GEmpty>
                ) : (
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) =>
                          column.id === "product_stock" &&
                          settings.product_stock === "0" ? (
                            ""
                          ) : (
                            <TableHeader
                              key={column.id}
                              style={{
                                minWidth: column.minWidth,
                                textAlign: column.align,
                              }}
                            >
                              {column.label}
                            </TableHeader>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productsData.map((c, i) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={c.id}
                          >
                            <TableRowData
                              style={{
                                BorderBottom: "1px solid #f4f5f9",
                              }}
                            >
                              {c.title}
                            </TableRowData>
                            <TableRowData>
                              {productCatData ? (
                                <GSelectbox
                                  placeholder=""
                                  defaultvalue={c.categories || ""}
                                  display="block"
                                  data={productCatData}
                                  id={c.id}
                                  option_key="title"
                                  option_value="id"
                                  onChange={(newVal) => {
                                    setSelectedProductId(c.id);
                                    updateSubaCategory(newVal, c.id, i);
                                  }}
                                  style={{
                                    border: "0",
                                    background: "#edf7fc",
                                    WebkitAppearance: "none",
                                    borderRadius: "20px",
                                    display: "inline-flex",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </TableRowData>
                            <TableRowData>
                              <img
                                alt={c.title}
                                src={ImgUrl("item") + "/" + c.thumb_photo}
                                onError={addDefaultLogo}
                                style={{
                                  height: "45px",
                                  width: "45px",
                                  borderRadius: "50%",
                                }}
                              />
                            </TableRowData>
                            <TableRowData
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {settings.currency_symbol}
                              {c.price}
                            </TableRowData>
                            {settings.product_stock === "1" ? (
                              <TableRowData
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {c.unlimited === 1
                                  ? "∞"
                                  : c.stock
                                  ? c.stock
                                  : "-"}
                              </TableRowData>
                            ) : (
                              ""
                            )}
                            <TableRowData>
                              <GSwitch
                                id={"item" + c.id}
                                uId={c.id}
                                currentValue={
                                  c.status === "true" ||
                                  c.status === "1" ||
                                  c.status === 1
                                    ? 1
                                    : 0
                                }
                                disabled={
                                  getUserType() ||
                                  isPermissionExist("product_update")
                                    ? false
                                    : true
                                }
                                onSwitchChange={(newVal, id) =>
                                  handleToggleStatus(newVal, id, i)
                                }
                              />
                            </TableRowData>
                            {getUserType() ||
                            isPermissionExist("product_update") ||
                            isPermissionExist("product_destroy") ? (
                              <TableRowData
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <div style={{ position: "relative" }}>
                                  <GActions
                                    edit={
                                      getUserType() == true ||
                                      isPermissionExist("product_update")
                                        ? true
                                        : false
                                    }
                                    delete={
                                      getUserType() == true ||
                                      isPermissionExist("product_destroy")
                                        ? true
                                        : false
                                    }
                                    viewProductOffReasons={
                                      getUserType() == true ? true : false
                                    }
                                    onEdit={() => {
                                      setSelectedProductData(c);
                                      setPevisible(true);
                                    }}
                                    onDelete={() => {
                                      setSelectedProductId(c.id);
                                      setDialogOpen(true);
                                    }}
                                    onShowReasons={() => {
                                      setSelectedProductId(c.id);
                                      setShowTurnOffReasonsForm(true);
                                    }}
                                    style={{ verticalAlign: "middle" }}
                                    menuStyle={{ fontWeight: "400" }}
                                  />
                                </div>
                                {/* {getUserType() ||
                                isPermissionExist("product_update") ? (
                                  <GButton
                                    variant="linkable"
                                    onClick={(e) => {
                                      setSelectedProductData(c);
                                      setPevisible(true);
                                    }}
                                  >
                                    <Edit fontSize="small" />
                                  </GButton>
                                ) : (
                                  ""
                                )}
                                {getUserType() ||
                                isPermissionExist("product_destroy") ? (
                                  <GButton
                                    variant="linkable"
                                    //  onClick={(e) =>
                                    //       window.confirm(
                                    //         "Are you sure you wish to delete this customer?"
                                    //       ) && deleteCustomer(c.id)
                                    // }

                                    onClick={(e) => {
                                      setSelectedProductId(c.id);
                                      setDialogOpen(true);
                                    }}
                                  >
                                    <Delete fontSize="small" />
                                  </GButton>
                                ) : (
                                  ""
                                )} */}
                              </TableRowData>
                            ) : (
                              ""
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            style={{
              background: "#ebf6fa",
              display: "inline-flex",
              width: "100%",
              padding: "15px",
              boxSizing: "border-box",
              border: "1px solid #e2e2e2",
            }}
          >
            <img
              src={CatIcon}
              alt="catIcon"
              style={{ width: "30px", marginRight: "10px" }}
            />
            <div>
              <h4 style={{ margin: "0 0 5px", fontWeight: "450" }}>
                {strings.s_categories_for_each_store_are_managed_seperately}
              </h4>
              <p style={{ margin: "0", color: "#777", fontSize: "15px" }}>
                <Link
                  to={{ pathname: "/categories", state: "product" }}
                  style={{ textDecoration: "underline" }}
                >
                  {strings.w_click_here}
                </Link>{" "}
                {strings.s_to_manage_categories}
              </p>
            </div>
          </div>
          <div className="listPaper" style={{ marginTop: "15px" }}>
            <GEmpty
              title={strings.w_products}
              subtitle={strings.s_please_choose_store_to_fetch_products}
            />
          </div>
        </React.Fragment>
      )}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_product_title}
          text={StringsContext.s_delete_product_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteProduct();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={pcvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setPcvisible(false)}
        style={{
          content: {
            width: "650px",
          },
        }}
        contentLabel="Product Create Form Modal"
      >
        <ProductCreatePopup
          pcvisible={pcvisible}
          setPcvisible={setPcvisible}
          storeId={filters.store_id}
          localizableKeys={productsLocalizableKeys}
          onSubmit={getProducts}
          settings={settings}
          isStockShow={settings.product_stock}
          isBarcodeShow={settings.product_barcode_enabled}
        />
      </Modal>

      <Modal
        isOpen={pevisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setPevisible(false)}
        style={{
          content: {
            width: "680px",
          },
        }}
        contentLabel="Product Edit Form Modal"
      >
        <ProductEditPopup
          pevisible={pevisible}
          setPevisible={setPevisible}
          storeId={filters.store_id}
          localizableKeys={productsLocalizableKeys}
          data={selectedProductData}
          onSubmit={getProducts}
          settings={settings}
          isStockShow={settings.product_stock}
          isBarcodeShow={settings.product_barcode_enabled}
          onLocalizationRemove={(ind) => {
            let findInd = productsData.findIndex(
              (nId) => nId?.id == selectedProductData.id
            );

            if (findInd > -1) {
              productsData[findInd].localizations.splice(ind, 1);
            }
          }}
          onLocalizationUpdate={(ind, data) => {
            let findInd = productsData.findIndex(
              (nId) => nId?.id == selectedProductData.id
            );

            if (findInd > -1) {
              productsData[findInd].localizations[ind] = data;
            }
          }}
        />
      </Modal>

      <Modal
        isOpen={showTurnOffReasonsForm}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setShowTurnOffReasonsForm(false)}
        style={{
          content: {
            width: "550px",
          },
        }}
        contentLabel="Product Unavailable Reasons Modal"
      >
        <ProductUnavailabilityReasons
          showTurnOffReasonsForm={showTurnOffReasonsForm}
          setShowTurnOffReasonsForm={setShowTurnOffReasonsForm}
          productId={selectedProductId}
          settings={settings}
        />
      </Modal>
    </div>
  );
}
