export class RepeatOrder {
  constructor(obj) {
    this.id = obj.product_id;
    this.variants = obj.variants.map((v) => new RepeatOrderItem(v));
    this.quantity = obj.quantity;
  }
}

export class RepeatOrderItem {
  constructor(obj) {
    this.id = parseInt(obj.product_variant_id);
  }
}
