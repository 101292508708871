import React, { useContext } from "react";
import { StringsContext } from "../DataFactory/useStrings";
import { TableContainer } from "@material-ui/core";
import useDataFctory from "../useDataFactory";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";

function ProductUnavailabilityReasons(props) {
  const [strings] = useContext(StringsContext);
  const { loading, Placeholder, data, CardShimmer } = useDataFctory(
    "product_unavailability_reasons",
    false,
    { product_id: props.productId }
  );

  const columns = [
    { id: "reason", label: strings.w_reason, minWidth: 130 },
    { id: "created", label: strings.w_created, minWidth: 100, align: "center" },
  ];

  if (props.showTurnOffReasonsForm === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">{strings.w_status_off_reasons}</div>
      <div className="modal-body">
        <TableContainer>
          {loading ? (
            <CardShimmer />
          ) : data && data.length > 0 ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    return (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((c, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{
                          BorderBottom: "1px solid #f4f5f9",
                        }}
                      >
                        {c.reason}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {c.created}
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Placeholder />
          )}
        </TableContainer>
      </div>
    </>
  );
}

export default ProductUnavailabilityReasons;
